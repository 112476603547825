
const backgroundColors = {
    1: 'black',
    2: 'pink',
    3: 'light-pink',
    4: 'gray',
    5: 'white',
    6: 'white-pink'
}
const getBackgroundColor = id => `background-${backgroundColors[id]}` || 'background-white';

export default getBackgroundColor;
