import {initializeNutasticApp} from "@nutastic/nutastic-react-app";
import {extractElementData, fetchApiData} from "@nutastic/nutastic-react-app/src/utils";
import AppLoading from "./components/app/AppLoading";
import App from "./components/app/App";
import messages from "./i18n/messages";
import WebFont from 'webfontloader';

const init = async () => {
    WebFont.load({
        google: {
            families: [
                'Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700;0,900',
                'Playfair Display',
                'Roboto:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700'
            ]
        }
    });

    console.log('initializing page');
    await initializeNutasticApp({
        AppComponent: App,
        LoadingComponent: AppLoading,
        initialState: extractElementData(),
        decorateStateFn: fetchApiData,
        messages,
        reducers: []
    });
}

init();
