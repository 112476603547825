/**
 * Returns filepath to image
 * @param {string} filename
 * @returns {string}
 */
const getPublicImagePath = filename => {
    return `/public/assets/img/${filename}`;
}

const getUploadedImagePath = filename => {
    return `/public/uploads/${filename}`;
}

export {
    getPublicImagePath,
    getUploadedImagePath
}
