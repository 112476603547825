/**
 * Returns filepath to image
 * @param {string} imgPath
 * @returns {string}
 */
const getBackgroundImg = imgPath => {
    return {
        backgroundImage: `url('${ imgPath }')`
    };
}

export {
    getBackgroundImg
}
