import React, {useEffect} from 'react';
import PageHeader from "../PageHeader";
import {MarkupRender, useAppState} from "@nutastic/nutastic-react-app";
import installShopify from "../../utils/installShopify";

const ShopPage = () => {
    useEffect(() => installShopify(), []);
    const {
        page: { title, content, field_10: subtext }
    } = useAppState();

    return <React.Fragment>
        <PageHeader subtext={subtext} title={title} className="background-white-pink" />
        <section className='page-section page-section--padded page-section--bordered'>
            <div className="container">
                { content && <MarkupRender content={content} /> }
                { content && <div className="clear clear--xxl"/> }
                <div className="shopifyWindow" id='collection-component-1626809882190'/>
            </div>
        </section>
    </React.Fragment>
};

export default ShopPage;
