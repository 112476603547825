import React, {Fragment} from 'react';
import {getPublicImagePath, getUploadedImagePath} from "../../refactor/fileHelper";
import Masonry from 'react-masonry-css';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from "swiper/react";
import Fade from 'react-reveal/Fade';
import SwiperCore, { Navigation, EffectFade, Autoplay } from 'swiper';
import {gridBreakpoints} from "../../utils/constants";
import {MarkupRender, useAppState} from "@nutastic/nutastic-react-app";
import {getBackgroundImg} from "../../refactor/imgHelper";

SwiperCore.use([Navigation, Autoplay, EffectFade]);

const HomePage = () => {
    const { slide_images, feature_images, page } = useAppState();
    const { content, field_1, field_2, field_3, field_5, field_6, field_7, field_8, field_9, num_5 } = page;
    const componentClass = 'home-page';
    const bannerClasses = classnames(`${componentClass}__hero-banner`);
    const masonryBreakpoints = {
        default: 2,
        [gridBreakpoints.sm]: 1,
        [gridBreakpoints.md]: 1
    };
    const swiperProps = {
        autoplay: { delay: 5000 },
        loop: true,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: { crossFade: true }
    };

    const slides = (slide_images || []).map( ({name, image}, index) => {
        return <SwiperSlide>
            <img src={ getPublicImagePath('blank.png') } style={ getBackgroundImg(getUploadedImagePath(image)) } alt={name} />
        </SwiperSlide>
    });

    const features = (feature_images || []).map( ({name, image, link}, index) => {
        return <Fade>
            <a href={link} className="feature-box" id={`box--${index}`} key={index}>
                <img src={ getPublicImagePath(`blank.png`) } alt={name} style={ getBackgroundImg(getUploadedImagePath(image)) } />
                <span>{name} <i className="ion-ios-arrow-dropright-circle" /></span>
            </a>
        </Fade>;
    });

    const exploreSectionEnabled = num_5 ? parseInt(num_5) === 1 : false;

    return <Fragment>
        <div className={ bannerClasses }>
            <Swiper { ...swiperProps } auotplay={{ delay: 7000 }}>{ slides }</Swiper>
            <div className='hero-text'>
                <h1 className="special-font"><MarkupRender content={field_1}/></h1>
                <MarkupRender content={content} className='hero-text-paragraph' />
                <a href={field_3} className="btn btn-primary">{ field_2 }</a>
            </div>
        </div>
        <section className="page-section page-section--bordered page-section--padded features">
            <div className="container">
                <div className="paragraph text-center mb-5"><MarkupRender content={field_5} /></div>
                <Masonry breakpointCols={masonryBreakpoints} className="masonry" columnClassName="masonry__column">
                    { features }
                </Masonry>
            </div>
        </section>
        { exploreSectionEnabled &&
        <section className="page-section page-section--bordered page-section--padded explore background-white-pink">
            <div className="container">
                <div className="explore__title">
                    <h1>{field_6}</h1>
                </div>
                <div className="explore__details">
                    <MarkupRender content={field_7}/>
                    {field_8 && field_9 &&
                    <div className="mt-5 text-left"><a href={field_9} className="btn btn-outline-primary">{field_8}</a></div>}
                </div>
            </div>
        </section> }
    </Fragment>
};

export default HomePage;
