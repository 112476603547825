import React, {Fragment} from 'react';
import {Route, Switch} from "react-router-dom";
import CustomPage from "../pages/CustomPage";
import Header from "../Header";
import Footer from "../Footer";
import HomePage from "../pages/HomePage";
import ShopPage from "../pages/ShopPage";
import ProductPage from "../pages/ProductPage";

const App = () => {
    const renderPageRoute = () => {
        return <Switch>
            <Route path='/' exact={true} component={HomePage}/>
            <Route path='/home' component={HomePage}/>
            <Route path='/shop/:slug' component={ProductPage}/>
            <Route path='/shop' component={ShopPage}/>
            <Route path='/page/:slug' component={CustomPage}/>
        </Switch>
    };

    return <Fragment>
        <Header />
        { renderPageRoute() }
        <Footer />
    </Fragment>
};

export default App;
