import React from 'react';
import Fade from 'react-reveal/Fade';
import classnames from 'classnames';
import {fadeAnimationProps} from "../utils/constants";
import {getPublicImagePath} from "../refactor/fileHelper";
import {ReactSVG} from "react-svg";
import {useAppState} from "@nutastic/nutastic-react-app";

const Footer = () => {
    const {
        footerNavItems = [],
        socialMedia,
        subscription_enabled,
        subscription_title,
        subscription_text,
        subscription_button_text,
        subscription_button_url
    } = useAppState();
    const componentClass = "footer";
    const copyrightYear = new Date().getFullYear();
    const classes = classnames(componentClass, 'page-section', 'background-gray', {
        'page-section--padded': subscription_enabled,
        'page-section--bordered': subscription_enabled
    })

    const navigationLinks = Object.values(footerNavItems).map((n,i) => {
        const { href, title, new_window } = n;
        return <a key={i} href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>
            { title }
        </a>;
    });

    const socialLinks = (socialMedia || []).map(({url, type},i) => {
        return <a href={url} target="_blank"><i className={`ion-logo-${type}`} /></a>;
    });

    return <footer className={classes}>
        <div className="container">
            <div className="text-center">
                {subscription_enabled && <Fade {...fadeAnimationProps}>
                    <div>
                        <h1 className="mb-3">{ subscription_title }</h1>
                        <div className="paragraph mt-5 mb-5">{ subscription_text }</div>
                        { subscription_button_text && <a href={subscription_button_url} className="btn btn-primary mt-3">{ subscription_button_text }</a> }
                    </div>
                    <div className="clear clear--xxl"/>
                    <div className="clear clear--xxl"/>
                </Fade>
                }
            </div>
        </div>

        <div className="text-center">
            <div className={`${componentClass}__content`}>
                <div className="container">
                    <ReactSVG className={ `${componentClass}__logo` } src={ getPublicImagePath('emblem.svg') } />
                    <div className={`${componentClass}__links`}>{ navigationLinks }</div>
                    { socialLinks.length > 0 && <div className={`${componentClass}__social`}>{ socialLinks }</div> }
                    <div className={`${componentClass}__copyright`}>
                        <p>&copy; {copyrightYear} Little Roomy, LLC. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>;
};

export default Footer;
