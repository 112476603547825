import React from 'react';
import {ReactSVG} from "react-svg";
import {getPublicImagePath} from "../../refactor/fileHelper";

const AppLoading = () => {
    return <div className="loading-page">
        <ReactSVG src={ getPublicImagePath('loading.svg') } />
    </div>;
};

export default AppLoading;
