
const installShopify = () => {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyCartInit;
};

const ShopifyCartInit = () => {
    var client = ShopifyBuy.buildClient({
        domain: 'a-little-roomy.myshopify.com',
        storefrontAccessToken: '54a73a89249aeb4734105d0e6a9ae4a9',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('collection', {
            id: '272288612524',
            node: document.getElementById('collection-component-1626809882190'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
                "product": {
                    "styles": {
                        "product": {
                            "@media (min-width: 601px)": {
                                "max-width": "calc(33.33333% - 30px)",
                                "margin-left": "30px",
                                "margin-bottom": "50px",
                                "width": "calc(33.33333% - 30px)"
                            },
                            "img": {
                                "height": "calc(100% - 15px)",
                                "position": "absolute",
                                "left": "0",
                                "right": "0",
                                "top": "0"
                            },
                            "imgWrapper": {
                                "padding-top": "calc(75% + 15px)",
                                "position": "relative",
                                "height": "0"
                            }
                        },
                        "title": {
                            "font-family": "Lato, sans-serif",
                            "font-size": "20px",
                            "color": "#d74464"
                        },
                        "button": {
                            "font-family": "Lato, sans-serif",
                            "font-weight": "bold",
                            "font-size": "16px",
                            "padding-top": "16px",
                            "padding-bottom": "16px",
                            ":hover": {
                                "background-color": "#c23d5a"
                            },
                            "background-color": "#d74464",
                            ":focus": {
                                "background-color": "#c23d5a"
                            },
                            "border-radius": "0px",
                            "padding-left": "46px",
                            "padding-right": "46px"
                        },
                        "quantityInput": {
                            "font-size": "16px",
                            "padding-top": "16px",
                            "padding-bottom": "16px"
                        },
                        "price": {
                            "font-family": "Roboto, sans-serif",
                            "font-weight": "bold",
                            "font-size": "17px",
                            "color": "#212121"
                        },
                        "compareAt": {
                            "font-family": "Roboto, sans-serif",
                            "font-weight": "bold",
                            "font-size": "14.45px",
                            "color": "#212121"
                        },
                        "unitPrice": {
                            "font-family": "Roboto, sans-serif",
                            "font-weight": "bold",
                            "font-size": "14.45px",
                            "color": "#212121"
                        }
                    },
                    "buttonDestination": "modal",
                    "contents": {
                        "button": false,
                        "options": false
                    },
                    "isButton": true,
                    "text": {
                        "button": "View Product"
                    },
                    "googleFonts": [
                        "Lato",
                        "Roboto"
                    ]
                },
                "productSet": {
                    "styles": {
                        "products": {
                            "@media (min-width: 601px)": {
                                "margin-left": "-30px"
                            }
                        }
                    }
                },
                "modalProduct": {
                    "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true
                    },
                    "styles": {
                        "product": {
                            "@media (min-width: 601px)": {
                                "max-width": "100%",
                                "margin-left": "0px",
                                "margin-bottom": "0px"
                            }
                        },
                        "button": {
                            "font-family": "Lato, sans-serif",
                            "font-weight": "bold",
                            "font-size": "16px",
                            "padding-top": "16px",
                            "padding-bottom": "16px",
                            ":hover": {
                                "background-color": "#c23d5a"
                            },
                            "background-color": "#d74464",
                            ":focus": {
                                "background-color": "#c23d5a"
                            },
                            "border-radius": "0px",
                            "padding-left": "46px",
                            "padding-right": "46px"
                        },
                        "quantityInput": {
                            "font-size": "16px",
                            "padding-top": "16px",
                            "padding-bottom": "16px"
                        },
                        "title": {
                            "font-family": "Lato, sans-serif",
                            "font-weight": "normal",
                            "font-size": "34px",
                            "color": "#d74464"
                        },
                        "price": {
                            "font-family": "Roboto, sans-serif",
                            "font-weight": "bold",
                            "font-size": "26px",
                            "color": "#212121"
                        },
                        "compareAt": {
                            "font-family": "Roboto, sans-serif",
                            "font-weight": "bold",
                            "font-size": "22.099999999999998px",
                            "color": "#212121"
                        },
                        "unitPrice": {
                            "font-family": "Roboto, sans-serif",
                            "font-weight": "bold",
                            "font-size": "22.099999999999998px",
                            "color": "#212121"
                        },
                        "description": {
                            "font-family": "Lato, sans-serif",
                            "font-size": "16px"
                        }
                    },
                    "googleFonts": [
                        "Lato",
                        "Roboto"
                    ],
                    "text": {
                        "button": "Add to Cart"
                    }
                },
                "option": {
                    "styles": {
                        "label": {
                            "font-family": "Lato, sans-serif"
                        },
                        "select": {
                            "font-family": "Lato, sans-serif"
                        }
                    },
                    "googleFonts": [
                        "Lato"
                    ]
                },
                "cart": {
                    "styles": {
                        "button": {
                            "font-family": "Lato, sans-serif",
                            "font-weight": "bold",
                            "font-size": "16px",
                            "padding-top": "16px",
                            "padding-bottom": "16px",
                            ":hover": {
                                "background-color": "#c23d5a"
                            },
                            "background-color": "#d74464",
                            ":focus": {
                                "background-color": "#c23d5a"
                            },
                            "border-radius": "0px"
                        },
                        "title": {
                            "color": "#212121"
                        },
                        "header": {
                            "color": "#212121"
                        },
                        "lineItems": {
                            "color": "#212121"
                        },
                        "subtotalText": {
                            "color": "#212121"
                        },
                        "subtotal": {
                            "color": "#212121"
                        },
                        "notice": {
                            "color": "#212121"
                        },
                        "currency": {
                            "color": "#212121"
                        },
                        "close": {
                            "color": "#212121",
                            ":hover": {
                                "color": "#212121"
                            }
                        },
                        "empty": {
                            "color": "#212121"
                        },
                        "noteDescription": {
                            "color": "#212121"
                        },
                        "discountText": {
                            "color": "#212121"
                        },
                        "discountIcon": {
                            "fill": "#212121"
                        },
                        "discountAmount": {
                            "color": "#212121"
                        }
                    },
                    "text": {
                        "title": "Your Cart",
                        "total": "Subtotal",
                        "empty": "No products have been added to your cart.",
                        "notice": "",
                        "button": "Complete Checkout"
                    },
                    "googleFonts": [
                        "Lato"
                    ]
                },
                "toggle": {
                    "styles": {
                        "toggle": {
                            "font-family": "Lato, sans-serif",
                            "font-weight": "bold",
                            "background-color": "#d74464",
                            ":hover": {
                                "background-color": "#c23d5a"
                            },
                            ":focus": {
                                "background-color": "#c23d5a"
                            }
                        },
                        "count": {
                            "font-size": "16px"
                        }
                    },
                    "googleFonts": [
                        "Lato"
                    ]
                },
                "lineItem": {
                    "styles": {
                        "variantTitle": {
                            "color": "#212121"
                        },
                        "title": {
                            "color": "#212121"
                        },
                        "price": {
                            "color": "#212121"
                        },
                        "fullPrice": {
                            "color": "#212121"
                        },
                        "discount": {
                            "color": "#212121"
                        },
                        "discountIcon": {
                            "fill": "#212121"
                        },
                        "quantity": {
                            "color": "#212121"
                        },
                        "quantityIncrement": {
                            "color": "#212121",
                            "border-color": "#212121"
                        },
                        "quantityDecrement": {
                            "color": "#212121",
                            "border-color": "#212121"
                        },
                        "quantityInput": {
                            "color": "#212121",
                            "border-color": "#212121"
                        }
                    }
                }
            },
        });
    });
};

export default installShopify;
