import React, {useState} from 'react';
import {ReactSVG} from "react-svg";
import {getPublicImagePath} from "../refactor/fileHelper";
import {ToggleMenu, useAppState} from "@nutastic/nutastic-react-app";
import classnames from "classnames";

const Header = () => {
    const { headerNavItems = [] } = useAppState();
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const componentClass = "header";
    const navigationLinks = Object.values(headerNavItems).map((n,i) => {
        const { href, title, new_window } = n;
        return <li key={i}>
            <a href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>{ title }</a>
        </li>;
    });

    const menuButtonClasses = classnames(`${componentClass}__menuBtn`, {
        "ion-ios-menu": !isMenuOpen,
        "ion-ios-close": isMenuOpen,
        "isOpen": isMenuOpen
    });

    return <header className={ componentClass }>
        <div className="container h-100">
            <i className={ menuButtonClasses } onClick={() => setIsMenuOpen(!isMenuOpen)} />
            <a href='/'><ReactSVG className={ `${componentClass}__logo` } src={ getPublicImagePath('logo.svg') } /></a>
            <a href='/'><ReactSVG className={ `${componentClass}__emblem` } src={ getPublicImagePath('emblem.svg') } /></a>
        </div>
        <ToggleMenu isOpen={isMenuOpen} animation="slideFromLeft" onOuterClick={() => setIsMenuOpen(false)}>
            <ul>{ navigationLinks }</ul>
        </ToggleMenu>
    </header>;
};

export default Header;
