import React, {useState} from 'react';
import {getPublicImagePath, getUploadedImagePath} from "../../refactor/fileHelper";
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';
import PageHeader from "../PageHeader";
import {MarkupRender, useAppState} from "@nutastic/nutastic-react-app";
import {getBackgroundImg} from "../../refactor/imgHelper";
import {gridBreakpoints} from "../../utils/constants";
import Masonry from "react-masonry-css";

const ProductPage = () => {
    const { page } = useAppState();
    const { content, image, image_1, image_2, image_3, title, field_10: price, field_9: url } = page;
    const componentClass = 'product';
    const [ activeImage, setActiveImage ] = useState(image_1 ? image_1
        : image_2 ? image_2
        : image_3 ? image_3
        : image
    );

    const renderThumbnails = () => {
        const thumbnails = image_1 || image_2 || image_3 ? [image_1, image_2, image_3] : [image];
        return thumbnails.filter(t => !!t).map((thumbnail, index) => {
            const onClick = () => setActiveImage(thumbnail);
            return <img
                onClick={onClick}
                src={ getPublicImagePath(`blank.png`) }
                alt={ `${title} | Image ${index}` }
                style={ getBackgroundImg(getUploadedImagePath(thumbnail)) }
                className={ thumbnail === activeImage ? 'active' : ''}
            />
        })
    };

    return <React.Fragment>
        <section className='page-section page-section--padded'>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/shop">Shop</a>
                    <span className="breadcrumbs-item">{ title }</span>
                </div>
                <div className={componentClass}>
                    <div className={`${componentClass}__thumbnails`}>
                        { renderThumbnails() }
                    </div>
                    <div className={`${componentClass}__image`}>
                        <img src={ getUploadedImagePath(activeImage) } alt={ title } />
                    </div>
                    <div className={`${componentClass}__details`}>
                        <h1>{ title }</h1>
                        <h4>${ price }</h4>
                        { content && <MarkupRender content={content} /> }
                        <a href={url} target="_blank" className="btn btn-primary">
                            Buy Now
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
};

export default ProductPage;
