import React from 'react';
import classnames from 'classnames';
import {getBackgroundImg} from "../refactor/imgHelper";
import {getUploadedImagePath} from "../refactor/fileHelper";

const getHeaderClass = type => {
    switch ((type || 0).toString()) {
        case '1': return 'full-layout'
        case '2': return 'left-layout'
        case '3': return 'right-layout'
        default: return 'basic-layout'
    }
};

const PageHeader = ({ className, image, subtext, title, type }) => {
    const componentClass = 'page-header';
    const classes = classnames(componentClass, className, `${componentClass}--${getHeaderClass(type)}`);
    const headerImgStyle = type && image ? getBackgroundImg(getUploadedImagePath(image)) : {};
    const useImage = !!(image && type);

    return <section className={classes}>
        { useImage && <div className={`${componentClass}__img`} style={headerImgStyle} /> }
        <div className={ type ? `${componentClass}__container` : 'container'}>
            <h1>{ title }</h1>
            { subtext && <p>{ subtext }</p> }
        </div>
    </section>
};

export default PageHeader;
